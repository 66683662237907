import "bootstrap/dist/css/bootstrap.min.css";
import "../css/calendar.css";
import { useState, useEffect, useContext } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import timeTestData from "./test.json";
import { parse, format, subMinutes, subHours, addMinutes } from "date-fns";
import MyContext from "../MyContext";
import axios from "axios";

export default function Calendar({ serviceDuration, setSwitchPage }) {
  const [numberOfDays, setNumberOfDays] = useState([]);
  const [blankDays, setBlankDays] = useState([]);

  const [loadingTime, setLoadingTime] = useState(false);

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const {
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    getDay,
    setGetDay,
    clickDateMonth,
    setClickDateMonth,
    selectedTime,
    setSelectedTime,
    selectedBranch,
    disableTime,
    setDisableTime,
  } = useContext(MyContext);

  const [timeList] = useState([
    { useTime: "10:00", displayTime: "10:00 am" },
    { useTime: "10:15", displayTime: "10:15 am" },
    { useTime: "10:30", displayTime: "10:30 am" },
    { useTime: "10:45", displayTime: "10:45 am" },
    { useTime: "11:00", displayTime: "11:00 am" },
    { useTime: "11:15", displayTime: "11:15 am" },
    { useTime: "11:30", displayTime: "11:30 am" },
    { useTime: "11:45", displayTime: "11:45 am" },
    { useTime: "12:00", displayTime: "12:00 pm" },
    { useTime: "12:15", displayTime: "12:15 pm" },
    { useTime: "12:30", displayTime: "12:30 pm" },
    { useTime: "12:45", displayTime: "12:45 pm" },
    { useTime: "13:00", displayTime: "01:00 pm" },
    { useTime: "13:15", displayTime: "01:15 pm" },
    { useTime: "13:30", displayTime: "01:30 pm" },
    { useTime: "13:45", displayTime: "01:45 pm" },
    { useTime: "14:00", displayTime: "02:00 pm" },
    { useTime: "14:15", displayTime: "02:15 pm" },
    { useTime: "14:30", displayTime: "02:30 pm" },
    { useTime: "14:45", displayTime: "02:45 pm" },
    { useTime: "15:00", displayTime: "03:00 pm" },
    { useTime: "15:15", displayTime: "03:15 pm" },
    { useTime: "15:30", displayTime: "03:30 pm" },
    { useTime: "15:45", displayTime: "03:45 pm" },
    { useTime: "16:00", displayTime: "04:00 pm" },
    { useTime: "16:15", displayTime: "04:15 pm" },
    { useTime: "16:30", displayTime: "04:30 pm" },
    { useTime: "16:45", displayTime: "04:45 pm" },
    { useTime: "17:00", displayTime: "05:00 pm" },
  ]);

  const generateCalendar = (year, month) => {
    const firstDay = new Date(year, month, 1).getDay();
    const blankNum = [];
    for (let i = 1; i <= firstDay; i++) {
      blankNum.push(i);
    }
    setBlankDays(blankNum);

    const totalDaysInMonth = new Date(year, month + 1, 0).getDate();
    const totalDaysArr = [];
    for (let i = 1; i <= totalDaysInMonth; i++) {
      totalDaysArr.push(i);
    }
    setNumberOfDays(totalDaysArr);

    if (month >= 12) {
      setSelectedYear(selectedYear + 1);
      setSelectedMonth(0);
    } else if (month <= -1) {
      setSelectedYear(selectedYear - 1);
      setSelectedMonth(11);
    }
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // const subtractTime = (time, duration) => {
  //   // Parse the time string into a Date object
  //   const parsedTime = parse(time, "hh:mm a", new Date());

  //   // Subtract 40 minutes from the parsed time
  //   const updatedTime = subMinutes(parsedTime, duration);

  //   // Format the updated time back to the desired format
  //   return format(updatedTime, "hh:mm a");
  // };

  const subtractTime = (time, duration) => {
    const parsedTime = parse(time, "HH:mm", new Date());
    const [amount, unit] = duration.split(" ");

    let updatedTime;
    if (unit.includes("hr")) {
      updatedTime = subMinutes(parsedTime, parseInt(amount * 60 - 1));
    } else if (unit.includes("min")) {
      updatedTime = subMinutes(parsedTime, parseInt(amount - 1));
    }

    return format(updatedTime, "HH:mm");
  };

  const addDuration = (time, duration) => {
    // Parse the time string into a Date object
    const parsedTime = parse(time, "HH:mm", new Date());
    const [amount, unit] = duration.split(" ");

    let updatedTime;
    if (unit.includes("hr")) {
      updatedTime = addMinutes(parsedTime, parseInt(amount * 60 - 1));
    } else if (unit.includes("min")) {
      updatedTime = addMinutes(parsedTime, parseInt(amount - 1));
    }

    // Format the updated time back to the desired format
    return format(updatedTime, "HH:mm");
  };

  const fetchTimeData = async (day, month) => {
    setLoadingTime(true);
    try {
      const data = { selectedYear, month, day, selectedTime, selectedBranch, serviceDuration };
      const response = await axios.post(process.env.REACT_APP_FETCHDATETIME, data);

      setTimeout(() => {
        // console.log(response.data);
        setDisableTime(response.data);
        setLoadingTime(false);
      }, 1000);
    } catch (error) {
      console.log("failed to fetch time data: ", error);
    }
  };

  // const originalTime = "15:00";
  // const durationTime = "1 hr";
  // const newTime = subtractTime(originalTime, durationTime);

  useEffect(() => {
    generateCalendar(selectedYear, selectedMonth);
    console.log(selectedYear, clickDateMonth, getDay, selectedTime, selectedBranch, serviceDuration);

    // console.log(getDay);
  }, [selectedMonth, selectedYear, getDay]);

  return (
    <div className="calendarView">
      <p className="text-center">Please select date and time.</p>
      <div className="row calendarRow">
        <div className="col-md-6 Calendar">
          <div className="row d-flex justify-content-center mb-3">
            <div className="col-3 d-flex justify-content-center">
              {currentYear < selectedYear || (currentYear === selectedYear && currentMonth < selectedMonth) ? (
                <div className="backNextBtn" onClick={() => setSelectedMonth(selectedMonth - 1)}>
                  <ChevronLeftIcon style={{ fontSize: "2.1rem" }} />
                </div>
              ) : (
                <div>
                  <ChevronLeftIcon style={{ fontSize: "2.1rem", color: "#d9d9d9" }} />
                </div>
              )}
            </div>
            <div className="col-5 d-flex justify-content-center">{monthNames[selectedMonth] + " " + selectedYear}</div>
            <div className="col-3 d-flex justify-content-center">
              {currentMonth + 2 === selectedMonth ? (
                <div>
                  <ChevronRightIcon style={{ fontSize: "2.1rem", color: "#d9d9d9" }} />
                </div>
              ) : (
                <div className="backNextBtn" onClick={() => setSelectedMonth(selectedMonth + 1)}>
                  <ChevronRightIcon style={{ fontSize: "2.1rem" }} />
                </div>
              )}
            </div>
          </div>

          <ul className="daysName">
            <li>Sun</li>
            <li>Mon</li>
            <li>Tue</li>
            <li>Wed</li>
            <li>Thu</li>
            <li>Fri</li>
            <li>Sat</li>
          </ul>
          <ul className="listOfdays">
            {blankDays.map((blank, index) => (
              <li key={index}></li>
            ))}

            {numberOfDays.map((numberOfDay, index) =>
              currentYear < selectedYear ||
              (selectedYear === currentYear && currentMonth < selectedMonth) ||
              (selectedYear === currentYear && currentMonth === selectedMonth && currentDay < numberOfDay) ? (
                <li
                  onClick={() => {
                    setGetDay(numberOfDay);
                    fetchTimeData(numberOfDay, selectedMonth);
                    setClickDateMonth(selectedMonth);
                    setSelectedTime("");
                  }}
                  className={
                    selectedMonth === clickDateMonth && getDay === numberOfDay ? "activeDay dateSelected" : "activeDay"
                  }
                  key={index}
                >
                  {numberOfDay}
                </li>
              ) : currentMonth === selectedMonth && currentDay === numberOfDay ? (
                <li className="currentDay" key={index}>
                  {numberOfDay}
                </li>
              ) : (
                <li key={index}>{numberOfDay}</li>
              )
            )}
          </ul>
        </div>
        <div className="col-md-6">
          {getDay === 0 ? (
            <div className="selectDateLabel">
              <span>Please select a date...</span>
            </div>
          ) : loadingTime === true ? (
            <div className="loader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <ul className="times">
              {timeList.map((time, index) => {
                const isInRange = disableTime.timeTaken.some(
                  (disTime) =>
                    (disTime.start <= time.useTime && addDuration(disTime.start, disTime.duration) >= time.useTime) ||
                    (subtractTime(disTime.start, serviceDuration) < time.useTime && disTime.start > time.useTime)
                );

                return isInRange ? (
                  ""
                ) : (
                  <li
                    key={index}
                    className={selectedTime === time.useTime ? "selected" : ""}
                    onClick={() => {
                      setSelectedTime(time.useTime);
                      setSwitchPage("inputForm");
                    }}
                  >
                    {time.displayTime}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
