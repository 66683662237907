import React from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Button, Form } from "react-bootstrap";
import MyContext from "../MyContext";
import { useContext, useState } from "react";
import "../css/bookingForm.css";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

export default function BookingForm() {
  const [isLoading, setIsLoading] = useState(false);
  const {
    selectedYear,
    getDay,
    clickDateMonth,
    selectedTime,
    serviceDuration,
    selectedService,
    selectedBranch,
    confirmPage,
    setConfirmPage,

    setPatientDetails,
  } = useContext(MyContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const formData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        patientStatus: data.patientStatus,
        notes: data.notes,
        clickDateMonth,
        selectedYear,
        getDay,
        selectedTime,
        serviceDuration,
        selectedService,
        selectedBranch,
      };
      const response = await axios.post(process.env.REACT_APP_SENDBOOKINGDETAILS, formData);
      console.log(response.data);
      if (response.data === "success") {
        setTimeout(() => {
          setConfirmPage(true);
          setPatientDetails(data);
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log("Fail send the data: ", error);
    }
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="bookingForm">
      <h5 className="text-center">Enter your information.</h5>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Form.Group as={Col} controlId="validationCustom01">
            <Form.Label className="mt-3">First Name</Form.Label>
            <Form.Control
              {...register("firstName", { required: "Please enter a name." })}
              type="text"
              placeholder="Enter first name"
              size="sm"
              isInvalid={!!errors.firstName}
            />
            <Form.Control.Feedback type="invalid">{errors.firstName?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="validationCustom02">
            <Form.Label className="mt-3">Last Name</Form.Label>
            <Form.Control
              {...register("lastName", { required: "Enter a last name." })}
              type="text"
              placeholder="Enter last name"
              size="sm"
              isInvalid={!!errors.lastName}
            />
            <Form.Control.Feedback type="invalid">{errors.lastName?.message}</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="validationCustom03">
            <Form.Label className="mt-3">Email</Form.Label>
            <Form.Control
              {...register("email", {
                required: "Enter a valid email.",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Enter a valid email.",
                },
              })}
              type="email"
              placeholder="Enter an email"
              size="sm"
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="validationCustom04">
            <Form.Label className="mt-3">Phone Number</Form.Label>
            <Form.Control
              {...register("phoneNumber", { required: "Enter your phone number." })}
              type="text"
              placeholder="Phone number"
              size="sm"
              isInvalid={!!errors.phoneNumber}
            />
            <Form.Control.Feedback type="invalid">{errors.phoneNumber?.message}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="validationCustom05">
            <Form.Label className="mt-3">Patient Status</Form.Label>
            <div className="mt-1">
              <Form.Check
                {...register("patientStatus", { required: "Select patient status." })}
                inline
                label="New"
                name="patientStatus"
                type="radio"
                id="new-radio"
                value="New"
                isInvalid={!!errors.patientStatus}
              />
              <Form.Check
                {...register("patientStatus", { required: "Select patient status." })}
                inline
                label="Returning"
                name="patientStatus"
                type="radio"
                id="returning-radio"
                value="Returning"
                isInvalid={!!errors.patientStatus}
              />
            </div>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              Notes to clinic <span style={{ fontSize: "13px" }}>(optional)</span>
            </Form.Label>
            <Form.Control {...register("notes")} as="textarea" rows={3} />
          </Form.Group>
        </Row>

        <Row>
          <Col className="d-flex justify-content-center">
            <Button type="submit" variant="primary" className="mt-3" disabled={isLoading}>
              <Spinner style={{ display: isLoading ? "inline-block" : "none" }} animation="border" size="sm" /> &nbsp;
              {isLoading ? "Requesting..." : "Request appointment"}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
