import { useState, useEffect, useContext } from "react";
import MyContext from "../MyContext";
import "../css/branches.css";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

export default function Branches() {
  const { selectedBranch, setSelectedBranch } = useContext(MyContext);

  const [branchesList, setBranchesList] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const getLocation = async () => {
    setIsloading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_FETCHLOCATION);
      console.log(response.statusText);
      if (response.statusText === "OK") {
        setBranchesList(response.data.branchList);
      }
    } catch (error) {
      console.log("Failed to get location: ", error);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <div className="location">
      {isLoading === true && (
        <div className="LoadingDiv">
          <Spinner className="spinnerLoads" />
        </div>
      )}
      <p className="text-center">Please select your preferred branch. </p>
      <ul>
        {branchesList.map((branch, index) => (
          <li
            className={selectedBranch === branch.name ? "branchSelected" : ""}
            key={index}
            onClick={() => setSelectedBranch(branch.name)}
          >
            {branch.name} <span className="text-start locLabel">{branch.location}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
