import Service from "./Service";
import MyProvider from "./MyProvider";
import ConfirmedPage from "./ConfirmedPage";
import { useContext } from "react";
import MyContext from "./MyContext";

export default function App() {
  const { confirmPage } = useContext(MyContext);
  return <>{confirmPage === true ? <ConfirmedPage /> : <Service />}</>;
}
