// MyProvider.js
import { useEffect, useState } from "react";
import MyContext from "./MyContext";

const MyProvider = ({ children }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [clickDateMonth, setClickDateMonth] = useState(0);
  const [getDay, setGetDay] = useState(0);
  const [selectedTime, setSelectedTime] = useState("");
  const [serviceDuration, setServiceDuration] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [confirmPage, setConfirmPage] = useState(false);
  const [patientDetails, setPatientDetails] = useState({});
  const [disableTime, setDisableTime] = useState([]);

  useEffect(() => {
    setSelectedService("");
    setSelectedTime("");
    setClickDateMonth(0);
    setGetDay(0);
  }, [selectedBranch]);

  return (
    <MyContext.Provider
      value={{
        selectedMonth,
        setSelectedMonth,
        selectedYear,
        setSelectedYear,
        getDay,
        setGetDay,
        clickDateMonth,
        setClickDateMonth,
        selectedTime,
        setSelectedTime,
        serviceDuration,
        setServiceDuration,
        selectedService,
        setSelectedService,
        selectedBranch,
        setSelectedBranch,
        confirmPage,
        setConfirmPage,
        patientDetails,
        setPatientDetails,
        disableTime,
        setDisableTime,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export default MyProvider;
