import "./css/confirmPage.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { useContext, useEffect, useState } from "react";
import MyContext from "./MyContext";

export default function ConfirmedPage() {
  const {
    selectedYear,
    getDay,
    clickDateMonth,
    selectedTime,
    serviceDuration,
    selectedService,
    selectedBranch,
    patientDetails,
  } = useContext(MyContext);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [timeList] = useState([
    { useTime: "10:00", displayTime: "10:00 am" },
    { useTime: "10:15", displayTime: "10:15 am" },
    { useTime: "10:30", displayTime: "10:30 am" },
    { useTime: "10:45", displayTime: "10:45 am" },
    { useTime: "11:00", displayTime: "11:00 am" },
    { useTime: "11:15", displayTime: "11:15 am" },
    { useTime: "11:30", displayTime: "11:30 am" },
    { useTime: "11:45", displayTime: "11:45 am" },
    { useTime: "12:00", displayTime: "12:00 pm" },
    { useTime: "12:15", displayTime: "12:15 pm" },
    { useTime: "12:30", displayTime: "12:30 pm" },
    { useTime: "12:45", displayTime: "12:45 pm" },
    { useTime: "13:00", displayTime: "01:00 pm" },
    { useTime: "13:15", displayTime: "01:15 pm" },
    { useTime: "13:30", displayTime: "01:30 pm" },
    { useTime: "13:45", displayTime: "01:45 pm" },
    { useTime: "14:00", displayTime: "02:00 pm" },
    { useTime: "14:15", displayTime: "02:15 pm" },
    { useTime: "14:30", displayTime: "02:30 pm" },
    { useTime: "14:45", displayTime: "02:45 pm" },
    { useTime: "15:00", displayTime: "03:00 pm" },
    { useTime: "15:15", displayTime: "03:15 pm" },
    { useTime: "15:30", displayTime: "03:30 pm" },
    { useTime: "15:45", displayTime: "03:45 pm" },
    { useTime: "16:00", displayTime: "04:00 pm" },
    { useTime: "16:15", displayTime: "04:15 pm" },
    { useTime: "16:30", displayTime: "04:30 pm" },
    { useTime: "16:45", displayTime: "04:45 pm" },
    { useTime: "17:00", displayTime: "05:00 pm" },
  ]);

  const time = (param) => {
    for (let i = 0; i < timeList.length; i++) {
      if (timeList[i].useTime === param) return timeList[i].displayTime;
    }
  };

  return (
    <>
      <div className="thankPage">
        <i className="fas fa-check text-center"></i>
        <h1 className="text-center">Request booking Successfully! Thank you!</h1>

        <p className="text-center">
          Schedule booking: <strong>{monthNames[clickDateMonth] + " " + getDay + ", " + time(selectedTime)}</strong>
        </p>

        <p className="text-center">
          Name: <strong>{patientDetails.firstName + " " + patientDetails.lastName}</strong>
        </p>
        <p className="text-center">
          Patient status: <strong>{patientDetails.patientStatus}</strong>
        </p>
        <p className="text-center">
          Service: <strong>{selectedService}</strong>
        </p>
        <p className="text-center">
          Branch: <strong>{selectedBranch}</strong>
        </p>
        <p className="text-center">
          Your booking is not yet confirmed. Please wait for a call or text message within the day to fully confirm your
          booking.{" "}
          <span className="noteMessage">
            Note: No reply to our call or message within the day will automatically cancel your booking.
          </span>
        </p>
      </div>
    </>
  );
}
