import "./css/service.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ListService from "./components/ListService";
import Calendar from "./components/Calendar";
import BookingForm from "./components/BookingForm";
import { useState, useEffect, useContext } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MyContext from "./MyContext";
import Branches from "./components/Branches";

export default function Service() {
  const [switchPage, setSwitchPage] = useState("selectBranch");

  const { serviceDuration, setServiceDuration, selectedService, setSelectedService, selectedBranch } =
    useContext(MyContext);

  const continueBtnActive = () => {
    if (switchPage === "selectBranch" && selectedBranch !== "") {
      return false;
    } else if (switchPage === "selectService" && selectedService !== "") {
      return false;
    } else {
      return true; // Or any other value you want to return in other cases
    }
  };

  const continueBtn = () => {
    if (switchPage === "selectBranch") {
      setSwitchPage("selectService");
    } else if (switchPage === "selectService") {
      setSwitchPage("selectDate");
    }
  };

  useEffect(() => {
    continueBtnActive();
    // console.log(continueBtnActive());
    // console.log(switchPage);
  }, [selectedBranch]);

  return (
    <>
      <div className="container">
        <div className={`serviceBox`}>
          <div>
            <div className="row">
              <div className="col">
                {switchPage !== "selectBranch" && (
                  <ArrowBackIcon
                    onClick={() => {
                      switchPage === "selectDate" && setSwitchPage("selectService");
                      switchPage === "inputForm" && setSwitchPage("selectDate");
                      switchPage === "selectService" && setSwitchPage("selectBranch");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
              <div className="col d-flex justify-content-end">
                {switchPage === "selectDate" ? (
                  <></>
                ) : (
                  switchPage !== "inputForm" && (
                    <button
                      disabled={continueBtnActive()}
                      className={continueBtnActive() === false ? "continueBtnEnable" : "continueBtnDisable"}
                      onClick={continueBtn}
                    >
                      Continue
                    </button>
                  )
                )}
              </div>
            </div>
          </div>

          <div>
            {switchPage === "selectDate" ? (
              <Calendar serviceDuration={serviceDuration} setSwitchPage={setSwitchPage} />
            ) : switchPage === "selectService" ? (
              <ListService
                setServiceDuration={setServiceDuration}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
              />
            ) : switchPage === "inputForm" ? (
              <BookingForm />
            ) : switchPage === "selectBranch" ? (
              <Branches />
            ) : (
              <Branches />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
