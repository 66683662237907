import { useState, useEffect } from "react";
import "../css/listService.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

export default function ListService({ setServiceDuration, selectedService, setSelectedService }) {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [servicesList, setServicesList] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  // const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  const fetchServices = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_FETCHSERVICES);
      // console.log(response.data);
      setServicesList(response.data);
      console.log(servicesList);
    } catch (error) {
      console.log("Error fetching Services: ", error);
    } finally {
      setIsloading(false);
    }
  };

  const handleRadioChange = (event, name) => {
    setSelectedService(name);
    setServiceDuration(event.target.value);
  };

  const renderList = (items) =>
    items.map((item, index) => (
      <div className="col serviceList mb-2" key={index} style={{ position: "relative" }}>
        <label className={selectedService === item.serviceName ? "labelSelected" : ""}>
          <input
            value={item.serviceDuration}
            type="radio"
            name="group1"
            onChange={(event) => handleRadioChange(event, item.serviceName)}
          />
          {item.serviceName}
        </label>
        <span className="duration">{item.serviceDuration}</span>
      </div>
    ));

  useEffect(() => {
    fetchServices();
    console.log("pacheck", servicesList);
    console.log("ito ung selected category: ", selectedCategory);
  }, [selectedCategory]);

  return (
    <>
      <p className="text-center mb-0">Please select a service</p>

      {isLoading === true && (
        <div className="LoadingDiv">
          <Spinner className="spinnerLoads" />
        </div>
      )}
      <div className="mb-3 categoryList">
        <Box sx={{ bgcolor: "background.paper" }}>
          <Tabs
            value={selectedCategory}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="-All-" />
            {servicesList.map((category, index) => (
              <Tab key={index} label={category.name} />
            ))}
          </Tabs>
        </Box>
      </div>

      <div className="content">
        {servicesList.map(
          (categoryList, index) =>
            selectedCategory === 0 && (
              <div key={index}>
                <h5 className="mb-3">{categoryList.name}</h5>
                {renderList(categoryList.services)}
              </div>
            )
        )}

        {servicesList.map(
          (categoryList, index) =>
            selectedCategory === index + 1 && (
              <div key={index}>
                <h5 className="mb-3">{categoryList.name}</h5>
                {renderList(categoryList.services)}
              </div>
            )
        )}
      </div>
    </>
  );
}
